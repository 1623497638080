
html, body, #root {
  text-align: center;
  height: 100%;
}

#root {
  display: flex;
  justify-content: center;
  align-items: center;
}

.App {
  margin: auto;
}

.drop {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 100px;
  border-width: 2px;
  border-radius: 10px;
  border-style: dashed;
  background-color: #985F6F22;
  color: #985F6F;
  outline: none;
  cursor: pointer;
  font-size: 1.3em;
}
